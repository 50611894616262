export default {
    btnsp: 'Select Plan',
    btns: 'Select',
    btnr: 'Remove',
    strone: 'Youre subscribing to',
    strtwo: 'Your Plan is',
    strthree: 'No payment method foundplease add a payment method',
    strfour: 'Ending In',
    strfive: 'Exp',
    strsix: 'Card Holder Name'
}
